import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(32.000000, 65.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="17.5 -60 -21.5 -38 -21.5 7 17.5 30 56.5 8 56.5 -37"
        />
        <path
          d="M 9.09375 0 L 1.140625 0 L 13.25 -35.046875 L 22.796875 -35.046875 L 34.875 0 L 26.9375 0 L 24.328125 -7.984375 L 11.6875 -7.984375 Z M 13.5625 -13.78125 L 22.46875 -13.78125 L 18.15625 -27.03125 L 17.875 -27.03125 Z M 13.5625 -13.78125 "
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
