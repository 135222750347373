import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="A" transform="translate(32.500000, 65.000000)">
        <path
          d="M 9.09375 0 L 1.140625 0 L 13.25 -35.046875 L 22.796875 -35.046875 L 34.875 0 L 26.9375 0 L 24.328125 -7.984375 L 11.6875 -7.984375 Z M 13.5625 -13.78125 L 22.46875 -13.78125 L 18.15625 -27.03125 L 17.875 -27.03125 Z M 13.5625 -13.78125 "
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
